import React, { useEffect } from 'react';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import SharedDeviationPage from '@wix/da-shared-react/pkg/DeviationPage';
import SharedStashPage from '@wix/da-shared-react/pkg/DeviationPage/StashPage';
import { useScrollRestore } from '@wix/da-shared-react/pkg/Duperbrowse/hooks/useScrollRestore';
import DrawerLoadable from '@wix/da-feedback-shared/pkg/Drawer/DrawerLoadable';
import FeedbackPageLoadable from '@wix/da-feedback-shared/pkg/FeedbackPage/FeedbackPageLoadable';
import { DuperbrowseViewContext } from '@wix/da-shared-react/pkg/Duperbrowse/DuperbrowseViewContext';
import { PapiDeviation } from '@wix/da-papi-types';
import { composeModalRenderers } from '@wix/da-shared-react/pkg/Modals/helpers';
import { feedbackModalRenderer } from '@wix/da-feedback-shared/pkg/project-tools';
import { adoptsModalRenderer } from '@wix/da-adopts-shared/pkg/project-tools';
import { submitModalRenderer } from '@wix/da-submit-shared/pkg/project-tools';

export interface Props {
  language: string;
  deviation?: PapiDeviation;
  isFeedbackPageOpen?: boolean;
  environment?: RuntimeEnvironment;
  isStash?: boolean;
  appLoaded: () => void;
}

const App: React.FC<Props> = ({
  language,
  deviation,
  isFeedbackPageOpen,
  environment,
  appLoaded,
  isStash,
}) => {
  useEffect(() => {
    appLoaded();
  }, [appLoaded]);

  useScrollRestore(!!isFeedbackPageOpen);

  return (
    <DaApp
      language={language}
      environment={environment}
      isDuperbrowseEnabled={false}
      disablePageWideNotifications
      disableHeadTags={true}
      ncContextProps={{
        PageComponent: FeedbackPageLoadable,
        DrawerComponent: DrawerLoadable,
      }}
      modalsRenderFunc={composeModalRenderers(
        feedbackModalRenderer,
        adoptsModalRenderer,
        submitModalRenderer
      )}
    >
      <ErrorBoundary debugComponent="DeviationApp">
        {isFeedbackPageOpen ? (
          <FeedbackPageLoadable />
        ) : (
          <DuperbrowseViewContext.Provider value="fullscreen">
            {isStash ? (
              <SharedStashPage deviation={deviation} />
            ) : (
              <SharedDeviationPage deviation={deviation} />
            )}
          </DuperbrowseViewContext.Provider>
        )}
      </ErrorBoundary>
    </DaApp>
  );
};
App.displayName = 'App';

export default App;
